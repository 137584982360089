import { Component, HostBinding, Input } from '@angular/core';
import { CustomDeviceDetectService } from '../../../service/custom-device-detect.service';


@Component({
  selector: 'asnt-nav-item',
  templateUrl: './asnt-nav-item.component.html',
  styleUrl: './asnt-nav-item.component.less'
})
export class AsntNavItemComponent {


  @Input() itemName!: string;
  @Input() isActive: boolean = false;

  deviceInfo: string = 'Unknown';

  @HostBinding('class') get hostClasses(): string {
    return `asnt-nav-item ${this.isActive ? 'active' : ''}`;
  }
  constructor(
    private customDeviceService: CustomDeviceDetectService,
  ) {


    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });




  }


}
