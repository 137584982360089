import { Component, HostBinding } from '@angular/core';
// import { DeviceDetectorService } from 'ngx-device-detector';
import { Subscription, Observable } from 'rxjs';
import { NavList } from '../asnt-nav/nav-list';
import { ViewportScroller } from '@angular/common';
import { PageInfoService } from '../../service/page-info.service';
import { CustomDeviceDetectService } from '../../service/custom-device-detect.service';

@Component({
  selector: 'asnt-footer',
  templateUrl: './asnt-footer.component.html',
  styleUrl: './asnt-footer.component.less'
})
export class AsntFooterComponent {

  navList = NavList;
  // deviceInfo: string = this.deviceService.getDeviceInfo().deviceType;
  deviceInfo: string = 'Unknown';
  currentYear: number = new Date().getFullYear();
  listHeight!: string;
  copyrightHeight!: string;

  pageOrder$!: Observable<number>;
  pageAboutAnchor$!: Observable<number>;
  getSubscription: Subscription = new Subscription();

  @HostBinding('class') get hostClasses(): string {
    return 'asnt-footer';
  }


  constructor(
    private pageInfoService: PageInfoService,
    // private deviceService: DeviceDetectorService,
    private customDeviceService: CustomDeviceDetectService,
    private viewportScroller: ViewportScroller,

  ) { }


  ngOnInit(): void {

    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });
    this.pageOrder$ = this.pageInfoService.getPageOrder();
    this.pageAboutAnchor$ = this.pageInfoService.getAboutAnchor();

    switch (this.deviceInfo) {
      case 'mobile': {
        this.listHeight = 'height: 200px;';
        this.copyrightHeight = 'height: 70px;';
        break;
      }
      default: {
        this.listHeight = 'height: 200px;';
        this.copyrightHeight = 'height: 90px;';
        break;
      }
    }

  }

  ngOnDestroy() {
    this.getSubscription.unsubscribe();
  }

  scrollTopPos() {
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  setPageAboutAnchor(currentAnchor: number) {
    this.pageInfoService.setAboutAnchor(currentAnchor);
  }


}
