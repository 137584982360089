import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsntFooterComponent } from './asnt-footer.component';
import { MainContainerComponent } from '../asnt-content/main-container/main-container.component';
import { RouterModule } from '@angular/router';



@NgModule({
  declarations: [
    AsntFooterComponent,
  ],
  imports: [
    RouterModule,
    CommonModule,
    MainContainerComponent,
  ],
  exports: [
    AsntFooterComponent,
  ]
})
export class AsntFooterModule { }
