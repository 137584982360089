<div id="main-nav" [className]="'asnt-nav-inner ' + deviceInfo"
    [ngClass]="{'open': navListDisplay == true, 'showIn': (displayNavItem$ | async) === true}">



    <main-container [overflow]="navContaninerOverflow" [useTopFixedElem]="true">

        @switch (deviceInfo) {

        <!-- 手機版 -->
        @case('mobile') {
        <div class="asnt-nav-logo">
            <asnt-svg-logo width="100px" [routerLink]="['/']"></asnt-svg-logo>
        </div>
        <div class="asnt-nav-mobile-menu" (click)="switchNavList()">
            @if(navListDisplay) {
            <mt-icon-close color="#FFFFFF" size="25px"></mt-icon-close>
            } @else {
            <mt-icon-menu color="#FFFFFF" size="25px"></mt-icon-menu>
            }
        </div>
        }

        @case('tablet') {
        <div class="asnt-nav-logo">
            <asnt-svg-logo width="120px" [routerLink]="['/']"></asnt-svg-logo>
        </div>
        <div class="asnt-nav-mobile-menu" (click)="switchNavList()">
            @if(navListDisplay) {
            <mt-icon-close color="#FFFFFF" size="25px"></mt-icon-close>
            } @else {
            <mt-icon-menu color="#FFFFFF" size="25px"></mt-icon-menu>
            }
        </div>
        }


        @case('desktop') {
        <div class="asnt-nav-logo">
            <asnt-svg-logo width="140px" [routerLink]="['/']"></asnt-svg-logo>
        </div>

        }

        <!-- 預設 -->
        @default {

        }

        }

        @if (displayNavItem$ | async) {
            
        <div class="asnt-nav-list">

            @for(itm of navList; track $index) {

            @if (itm.displayStatus) {

            <asnt-nav-item [routerLink]="[itm.routePath]" (click)="clickAsntNavItem();"
                [isActive]="itm.order === (pageOrder$ | async)" [itemName]="itm.name" />

            }

            }

            
            @if (isLogin) {
                <div class="btns" (click)="logoutClick()">
                    <mat-icon class="material-symbols-outlined">logout</mat-icon>
                </div>
            }
            
        </div>

        }







    </main-container>




</div>