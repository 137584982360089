import { CommonModule } from '@angular/common';
import { Component, HostBinding, Renderer2, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { CustomDeviceDetectService } from '../../../service/custom-device-detect.service';
import { LoadTimerService } from '../../../service/load-timer.service';
import { Observable } from 'rxjs';
import { imgPath } from '../../../app-path';

@Component({
  selector: 'asnt-loading-main',
  standalone: true,
  imports: [
    CommonModule,

  ],
  templateUrl: './asnt-loading-main.component.html',
  styleUrl: './asnt-loading-main.component.less'
})
export class AsntLoadingMainComponent {

  isLoading$!: Observable<boolean>;
  imagePath = imgPath.imageSrc;

  deviceInfo: string = 'Unknown';

  @HostBinding('class') get hostClasses() {
    return `asnt-loading-main}`;
  }


  constructor(
    private renderer: Renderer2,
    private customDeviceService: CustomDeviceDetectService,
    private loadTimerService: LoadTimerService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) { }

  ngOnInit(): void {

    this.isLoading$ = this.loadTimerService.getMainLoadStatus();

    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });

    // 先檢查是否在瀏覽器環境下運作
    if (isPlatformBrowser(this.platformId)) {

      this.isLoading$.subscribe((status) => {
        // console.log(status);
        if (status) {
          this.renderer.addClass(document.body, 'fixed-scroll');
        } else {
          this.renderer.removeClass(document.body, 'fixed-scroll');
        }
      });


    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.removeClass(document.body, 'fixed-scroll');
    }
  }

}
