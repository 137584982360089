import { Component, HostBinding } from '@angular/core';
import { AsntIconBasedComponent } from '../../asnt-icon-based/asnt-icon-based.component';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'mt-icon-account-circle',
  standalone: true,
  imports: [
    CommonModule,
  ],
  templateUrl: './mt-icon-account-circle.component.html',
  styleUrl: './mt-icon-account-circle.component.less'
})
export class MtIconAccountCircleComponent extends AsntIconBasedComponent {

  @HostBinding('class') get hostClasses(): string {
    return `asnt-mt-icon asnt-mt-icon-arrow-account-circle`;
  }
}
