import { Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'asnt-svg-logo',
  standalone: true,
  imports: [],
  templateUrl: './asnt-svg-logo.component.html',
  styleUrl: './asnt-svg-logo.component.less'
})
export class AsntSvgLogoComponent {
  
  @Input() width: string = '150px';
  @HostBinding('class') get hostClasses(): string {
    return `asnt-svg-logo`;
  }
  @HostBinding('style') get hostStyles(): string {
    return `width:${this.width} `;
  }
}
