<div [classList]="'asnt-footer-list ' + deviceInfo">
    @if (deviceInfo == 'desktop') {
    <main-container>
        @for(itm of navList; track $index) {

        @if(itm.displayStatus) {
        <div class="itm" [style.width]="'calc(100% / '+ navList.length + ')'">
            <div class="title">
                <a [routerLink]="[itm.routePath]" (click)="scrollTopPos();">{{itm.name}}</a>
            </div>
            <div class="childList">
                @for(citm of itm.childList; track $index) {
                <div class="citm">
                    @if (citm.fragment === '') {
                    <a [routerLink]="'/' + [itm.routePath]+'/'+[citm.routePath]"
                        (click)="scrollTopPos();">{{citm.name}}</a>
                    } @else {

                    <!-- 如果在關於我們頁面上 -->
                    @if((pageOrder$ | async) === 1) {
                    <a (click)="setPageAboutAnchor(citm.order);">{{citm.name}}</a>
                    } @else {
                    <a [routerLink]="'/' + [itm.routePath]+'/'+[citm.routePath]"
                        fragment="{{citm.fragment}}">{{citm.name}}</a>
                    }

                    }

                </div>
                }
            </div>

        </div>
        }
        }
    </main-container>
    }
</div>

<div [classList]="'asnt-footer-copyright '+ deviceInfo" [ngClass]="{'pageHome': (pageOrder$ | async) === 0}">
    <main-container>
        <p>&copy; {{currentYear}} Ascent-global All rights reserved.</p>
    </main-container>
</div>