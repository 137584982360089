import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AsntNavComponent } from './asnt-nav.component';
import { AsntSvgLogoComponent } from '../asnt-svg/asnt-svg-logo/asnt-svg-logo.component';
import { AsntNavItemComponent } from './asnt-nav-item/asnt-nav-item.component';
import { RouterLink } from '@angular/router';
import { MainContainerComponent } from '../asnt-content/main-container/main-container.component';
import { MtIconMenuComponent } from '../asnt-icon/asnt-mt-icon/mt-icon-menu/mt-icon-menu.component';
import { MtIconCloseComponent } from '../asnt-icon/asnt-mt-icon/mt-icon-close/mt-icon-close.component';
import {MatDialogModule} from '@angular/material/dialog';
import { MtIconAccountCircleComponent } from '../asnt-icon/asnt-mt-icon/mt-icon-account-circle/mt-icon-account-circle.component';
import { MtIconMemberComponent } from '../asnt-icon/asnt-mt-icon/mt-icon-member/mt-icon-member.component';
import { AsntLoadingLineComponent } from '../asnt-loading/asnt-loading-line/asnt-loading-line.component';
import { MatIconModule } from '@angular/material/icon';


@NgModule({
  declarations: [
    AsntNavComponent,
    AsntNavItemComponent,
  ],
  imports: [
    CommonModule,
    AsntSvgLogoComponent,
    MainContainerComponent,
    MtIconMenuComponent,
    MtIconCloseComponent,
    MtIconAccountCircleComponent,
    MtIconMemberComponent,
    RouterLink,
    AsntLoadingLineComponent,
    MatDialogModule,
    MatIconModule,
  ],
  exports: [
    AsntNavComponent,
    AsntNavItemComponent,
  ]
})
export class AsntNavModule { }
