<svg id="ascent-logo" data-name="ascent-logo" viewBox="0 0 85.6 27.33">
    <defs>
        <style>
            .cls-1 {
                fill: #fff;
            }

            .cls-2 {
                opacity: .7;
            }

            .cls-2,
            .cls-3,
            .cls-4,
            .cls-5,
            .cls-6 {
                fill: #e7ba2b;
            }

            .cls-2,
            .cls-3,
            .cls-4,
            .cls-6 {
                isolation: isolate;
            }

            .cls-3 {
                opacity: .55;
            }

            .cls-4 {
                opacity: .45;
            }

            .cls-6 {
                opacity: .85;
            }
        </style>
    </defs>
    <polygon class="cls-5" points="0 27.33 11.16 27.33 19.8 14.01 19.8 0 0 27.33" />
    <polygon class="cls-4" points="29.26 1.72 29.26 25.7 30.51 27.33 30.51 0 29.26 1.72" />
    <polygon class="cls-3" points="28.27 24.39 28.27 3.1 26.96 4.9 26.96 22.68 28.27 24.39" />
    <polygon class="cls-2" points="25.96 21.37 25.96 6.28 24.84 7.82 24.84 19.91 25.96 21.37" />
    <polygon class="cls-6" points="23.84 18.6 23.84 9.2 22.68 10.81 22.68 17.08 23.84 18.6" />
    <polygon class="cls-5" points="21.68 12.19 20.35 14.03 21.68 15.77 21.68 12.19" />
    <polygon class="cls-4" points="29.26 27.33 30.51 27.33 29.26 25.7 29.26 27.33" />
    <polygon class="cls-4" points="26.96 22.68 26.96 27.33 28.27 27.33 28.27 24.39 26.96 22.68" />
    <polygon class="cls-4" points="24.84 19.91 24.84 27.33 25.96 27.33 25.96 21.37 24.84 19.91" />
    <polygon class="cls-4" points="22.68 17.08 22.68 27.33 23.84 27.33 23.84 18.6 22.68 17.08" />
    <polygon class="cls-4" points="20.35 14.03 20.35 27.33 21.68 27.33 21.68 15.77 20.35 14.03" />
    <g>
        <path class="cls-1"
            d="M37.97,10.13c0-1.38,.92-2.18,2.83-2.18h2.08v-.89c0-1.59-.89-1.77-2.23-1.79-.61,0-1.24,.08-1.8,.16v-.37c.6-.12,1.27-.25,2.12-.25,1.8,0,3.01,.47,3.01,2.3v5.14h-1.08v-.47c-.46,.3-1.21,.56-2.18,.56-1.88,0-2.73-.89-2.73-2.22m4.9,1.41v-3.2h-1.8c-1.53,0-1.92,.5-1.92,1.74s.41,1.8,1.85,1.8c.85,0,1.59-.22,1.88-.34" />
        <path class="cls-1"
            d="M46.8,12.12v-.39c.76,.09,1.39,.14,2.23,.14,1.66,0,2.44-.45,2.46-1.37,0-.45-.14-.7-.49-.92-.34-.23-.87-.39-1.62-.58-.76-.2-1.41-.39-1.82-.73-.41-.33-.64-.75-.64-1.41,0-1.68,1.51-2.05,2.69-2.05,.86,0,1.57,.09,2.15,.22v.39c-.6-.09-1.25-.14-1.85-.14-1.36,0-1.99,.39-2,1.24,0,.48,.17,.76,.49,.96,.34,.22,.84,.36,1.48,.53,.86,.22,1.51,.42,1.94,.73,.44,.33,.67,.76,.66,1.48-.03,1.57-1.51,2.11-3.19,2.11-1.07,0-1.74-.09-2.49-.22" />
        <path class="cls-1"
            d="M55.09,8.59c0-2.49,1.3-3.78,3.8-3.78,.7,0,1.3,.09,1.79,.19v.39c-.47-.06-.96-.11-1.45-.11-2.14,0-2.95,.89-2.95,3.28s.81,3.31,2.95,3.31c.49,0,1.04-.05,1.51-.11v.39c-.47,.09-1.15,.19-1.85,.19-2.52,0-3.8-1.31-3.8-3.75" />
        <path class="cls-1"
            d="M62.97,8.57c0-2.46,1.39-3.78,3.22-3.78s3.19,1.2,3.19,3.68c0,.08,0,.2-.03,.31h-5.21c.03,2.47,.79,3.08,2.92,3.08,.64,0,1.24-.05,1.86-.17v.37c-.63,.17-1.28,.26-2.17,.26-2.2,0-3.79-.84-3.79-3.76m1.18-.2h4.08c-.05-2.11-.46-3.12-2.02-3.12s-2.02,1.04-2.06,3.12" />
        <path class="cls-1"
            d="M72.44,4.89h1.11v.5c.55-.31,1.44-.59,2.35-.59,1.74,0,2.76,.73,2.76,2.74v4.72h-1.13V7.55c0-1.65-.6-2.21-2.14-2.21-.72,0-1.27,.14-1.82,.33v6.59h-1.15V4.89h.02Z" />
        <path class="cls-1"
            d="M81.23,4.89h1.18V2.73h1.13v2.16h2.06v.44h-2.06v5.27c0,.93,.38,1.26,1.24,1.26,.32,0,.52-.03,.82-.09v.37c-.35,.11-.67,.17-1.25,.17-1.08,0-1.94-.4-1.94-2.02V5.32h-1.18v-.44h0Z" />
        <g>
            <polygon class="cls-1" points="53.15 23.04 54.13 21.02 55.02 21.02 54.04 23.04 53.15 23.04" />
            <polygon class="cls-1" points="55.94 24.34 57.07 21.95 57.97 21.95 56.83 24.34 55.94 24.34" />
            <polygon class="cls-1" points="54.24 24.34 55.37 21.95 56.27 21.95 55.13 24.34 54.24 24.34" />
            <polygon class="cls-1" points="50.96 21.13 53.38 20.15 53.38 20.99 50.96 21.96 50.96 21.13" />
            <path class="cls-1"
                d="M57.7,24.34v-.73h.79v-1.28h-4.38v-.61h4.74c.42,0,.48,.06,.48,.48v1.64c0,.42-.06,.48-.48,.48h-1.14Z" />
            <path class="cls-1" d="M51.07,24.34v-.73h.9v-5.9h.83v5.85c0,.43-.35,.77-.77,.77,0,0-.96,0-.96,0Z" />
            <polygon class="cls-1" points="50.7 19.36 51 18.75 53.72 18.75 53.72 19.36 50.7 19.36" />
            <path class="cls-1"
                d="M54.28,20.24v-2.19c0-.18,.14-.33,.33-.33h4.22c.18,0,.33,.14,.33,.33v2.19s-4.88,0-4.88,0Zm.35-.23h4v-1.98h-4v1.98Z" />
            <polygon class="cls-1" points="54.45 20.24 54.67 19.67 58.63 19.67 58.63 20.24 54.45 20.24" />
            <polygon class="cls-1" points="54.45 19.25 54.67 18.7 58.63 18.7 58.63 19.25 54.45 19.25" />
            <polygon class="cls-1" points="54.47 18.27 54.68 17.72 58.64 17.72 58.64 18.27 54.47 18.27" />
            <rect class="cls-1" x="54.28" y="18.03" width=".69" height="1.98" />
            <rect class="cls-1" x="58.46" y="18.03" width=".46" height="1.98" />
            <polygon class="cls-1" points="53.36 21.29 53.66 20.73 59.16 20.73 59.16 21.29 53.36 21.29" />
            <rect class="cls-1" x="54.28" y="18.01" width=".83" height="1.91" />
            <polygon class="cls-1" points="58.31 19.92 58.31 17.9 59.16 18.13 59.16 19.92 58.31 19.92" />
        </g>
        <g>
            <polygon class="cls-1" points="39.67 18.48 39.96 17.91 45.6 17.91 45.6 18.48 39.67 18.48" />
            <polygon class="cls-1" points="37.97 19.15 38.78 17.6 39.28 17.6 39.28 19.15 37.97 19.15" />
            <polygon class="cls-1" points="39.67 19.47 39.96 18.91 45.6 18.91 45.6 19.47 39.67 19.47" />
            <polygon class="cls-1" points="39.67 20.47 39.96 19.92 45.6 19.92 45.6 20.47 39.67 20.47" />
            <polygon class="cls-1" points="39.67 21.47 39.96 20.92 45.6 20.92 45.6 21.47 39.67 21.47" />
            <polygon class="cls-1" points="38.49 21.47 38.49 18.88 39.04 17.61 39.41 17.61 39.41 21.47 38.49 21.47" />
            <rect class="cls-1" x="42.13" y="17.49" width=".97" height="3.49" />
            <path class="cls-1"
                d="M41.1,23.57c-.43,0-.48-.06-.48-.48v-1.1h.95v.93h1.01v-.93h.95v1.1c0,.43-.06,.48-.48,.48h-1.95Z" />
            <path class="cls-1" d="M38.52,24.35v-1.59c0-.43,.35-.77,.77-.77h2.24v.72h-2.18v1.65h-.83Z" />
            <path class="cls-1"
                d="M43.98,24.35v-.73h.79v-.93h-2.19v-.72h2.26c.43,0,.77,.35,.77,.77v.82c0,.43-.35,.77-.77,.77h-.86Z" />
        </g>
        <g>
            <polygon class="cls-1" points="83.9 21.68 83.9 19.79 84.77 20 84.77 21.68 83.9 21.68" />
            <polygon class="cls-1" points="84.42 21.68 84.53 21.33 85.41 21.33 85.41 21.68 84.42 21.68" />
            <path class="cls-1" d="M84.22,20.79v-.89h-1.18v-.27h1.4c.19,0,.33,.13,.33,.29v.86h-.54Z" />
            <rect class="cls-1" x="83.96" y="20.03" width=".81" height="1.48" />
            <polygon class="cls-1" points="81.29 19.99 81.5 19.63 83.98 19.63 83.98 19.99 81.29 19.99" />
            <polygon class="cls-1" points="77.66 20.21 77.99 19.65 80.86 19.65 80.86 20.21 77.66 20.21" />
            <path class="cls-1"
                d="M81.29,21.02c.9-.4,.94-.97,.94-.97v-.19h.68v.3c0,.08-.02,.18-.06,.26-.04,.12-.08,.23-.14,.34-.07,.14-.16,.26-.27,.38-.13,.13-.27,.25-.45,.35-.14,.08-.27,.14-.39,.19h-.01s-.09,.04-.14,.05l-.07,.02s-.08,.02-.11,.02v-.74h.01Z" />
            <polygon class="cls-1" points="77.3 22.66 77.63 22.1 85.6 22.1 85.6 22.66 77.3 22.66" />
            <rect class="cls-1" x="80.92" y="21.85" width="1.02" height="2.53" />
            <polygon class="cls-1" points="77.68 24.39 78.57 23.04 79.56 23.04 78.67 24.39 77.68 24.39" />
            <polygon class="cls-1" points="84.39 24.39 83.5 23.04 84.51 23.04 85.39 24.39 84.39 24.39" />
            <polygon class="cls-1" points="81.26 20.38 82.05 20.38 83.17 21.68 82.39 21.68 81.26 20.38" />
            <polygon class="cls-1" points="77.78 21.13 80.86 20.75 80.86 21.37 77.78 21.75 77.78 21.13" />
            <polygon class="cls-1" points="78.95 21.22 78.95 19.84 79.81 20 79.81 21.22 78.95 21.22" />
            <rect class="cls-1" x="79" y="20.01" width=".81" height="1.07" />
            <rect class="cls-1" x="77.57" y="17.54" width=".97" height="1.7" />
            <rect class="cls-1" x="77.63" y="17.54" width=".81" height="1.54" />
            <rect class="cls-1" x="79.38" y="18.2" width=".86" height="1.04" />
            <rect class="cls-1" x="79.42" y="18.2" width=".81" height=".94" />
            <rect class="cls-1" x="81.83" y="17.54" width=".86" height="1.7" />
            <rect class="cls-1" x="81.87" y="17.54" width=".81" height="1.54" />
            <rect class="cls-1" x="83.63" y="18.2" width=".86" height="1.04" />
            <rect class="cls-1" x="83.68" y="18.2" width=".81" height=".94" />
            <polygon class="cls-1" points="78.09 18.51 78.41 17.96 81.38 17.96 81.38 18.51 78.09 18.51" />
            <polygon class="cls-1" points="82.12 18.51 82.45 17.96 85.4 17.96 85.4 18.51 82.12 18.51" />
        </g>
        <g>
            <g>
                <rect class="cls-1" x="70.98" y="18.22" width=".46" height="1.98" />
                <rect class="cls-1" x="70.83" y="18.31" width=".83" height="2.26" />
                <path class="cls-1" d="M67.78,20.42v-.23h3.36v-1.98h-3.36v-.3h3.56c.18,0,.33,.14,.33,.33v2.19h-3.89Z" />
                <polygon class="cls-1" points="64.6 18.34 64.81 17.95 66.43 17.95 66.61 18.34 64.6 18.34" />
                <polygon class="cls-1" points="65.04 20.42 65.28 20.03 66.47 20.03 66.47 20.42 65.04 20.42" />
                <path class="cls-1" d="M66.35,18.24h-1.15v-.3h1.36c.16,0,.3,.12,.33,.28v.06l-.53,.81v-.84h-.01Z" />
                <polygon class="cls-1"
                    points="65.29 20.15 65.26 20.06 66.37 18.35 66.83 18.35 65.67 20.15 65.29 20.15" />
                <polygon class="cls-1" points="64.46 20.42 64.72 20.03 65.89 20.03 65.89 20.42 64.46 20.42" />
                <polygon class="cls-1" points="64.73 20.15 64.7 20.06 65.88 18.2 66.55 18.2 65.31 20.15 64.73 20.15" />
                <path class="cls-1" d="M66.54,20.33h-1.15v-.3h1.36c.16,0,.3,.12,.33,.28v.05l-.53,.74v-.77h-.01Z" />
                <rect class="cls-1" x="69.05" y="17.54" width=".83" height="5.65" />
                <path class="cls-1"
                    d="M64.31,23.57l.13-.05c.06-.02,1.57-.68,1.76-3.29l.75,.06s.11,.13,.12,.14c-.28,3.18-2.27,3.97-2.37,4.01h-.05v.02c-.06,.02-.19,.08-.35,.14v-1.02h.01Z" />
                <polygon class="cls-1" points="67.22 18.47 67.51 17.9 71.3 17.9 71.3 18.47 67.22 18.47" />
                <polygon class="cls-1" points="67.38 20.58 67.67 20.01 71.46 20.01 71.46 20.58 67.38 20.58" />
                <polygon class="cls-1" points="67.38 21.62 67.67 21.06 71.77 21.06 71.77 21.62 67.38 21.62" />
                <polygon class="cls-1" points="66.97 22.66 67.26 22.11 72.16 22.11 72.16 22.66 66.97 22.66" />
                <polygon class="cls-1" points="66.97 19.52 67.26 18.96 72.16 18.96 72.16 19.52 66.97 19.52" />
            </g>
            <path class="cls-1"
                d="M67.7,23.47c-1.06-.13-1.5-.77-1.52-.8l-.02-.02c-.32-.43-.7-1.35-.84-1.72h-.9s.04,.09,.04,.12c.2,.54,.61,1.51,1.01,2.07,.12,.18,.8,1.08,2.26,1.24h4.44v-.88h-4.45Z" />
        </g>
    </g>
</svg>



<!-- 
<svg id="ascent-logo" data-name="ascent-logo" viewBox="0 0 538.45 163.44">
  <g>
    <path d="M240.2,63.25c0-8.66,5.73-13.62,17.68-13.62h13v-5.54c0-9.92-5.54-11.09-13.95-11.19-3.82,0-7.74.49-11.28.97v-2.33c3.73-.78,7.93-1.56,13.28-1.56,11.28,0,18.83,2.92,18.83,14.4v32.2h-6.79v-2.92c-2.87,1.85-7.55,3.5-13.67,3.5-11.76,0-17.11-5.55-17.11-13.91ZM270.88,72.1v-20.04h-11.28c-9.56,0-12.04,3.11-12.04,10.89s2.58,11.28,11.56,11.28c5.35,0,9.94-1.36,11.76-2.14Z" style="fill: #040000; stroke-width: 0px;"/>
    <path d="M295.44,75.7v-2.43c4.78.58,8.7.88,13.95.88,10.42,0,15.29-2.82,15.39-8.56.1-2.82-.86-4.38-3.06-5.74-2.1-1.46-5.45-2.43-10.13-3.6-4.78-1.26-8.79-2.43-11.37-4.57-2.58-2.04-4.01-4.67-4.01-8.85,0-10.51,9.46-12.84,16.82-12.84,5.35,0,9.84.58,13.48,1.36v2.43c-3.73-.58-7.84-.88-11.56-.88-8.51,0-12.42,2.43-12.52,7.78,0,3.02,1.05,4.77,3.06,6.03,2.1,1.36,5.26,2.24,9.27,3.31,5.35,1.36,9.46,2.63,12.14,4.57,2.77,2.04,4.21,4.77,4.11,9.24-.19,9.82-9.46,13.23-19.98,13.23-6.69,0-10.9-.58-15.58-1.36Z" style="fill: #040000; stroke-width: 0px;"/>
    <path d="M347.34,53.62c0-15.56,8.12-23.64,23.8-23.64,4.4,0,8.12.58,11.18,1.17v2.43c-2.96-.39-6.02-.68-9.08-.68-13.38,0-18.45,5.55-18.45,20.53s5.06,20.72,18.45,20.72c3.06,0,6.5-.29,9.46-.68v2.43c-2.96.58-7.17,1.17-11.56,1.17-15.77,0-23.8-8.17-23.8-23.44Z" style="fill: #040000; stroke-width: 0px;"/>
    <path d="M396.66,53.52c0-15.37,8.7-23.64,20.17-23.64s19.97,7.49,19.97,23.05c0,.49,0,1.27-.19,1.95h-32.59c.19,15.47,4.97,19.26,18.25,19.26,4.01,0,7.74-.29,11.66-1.07v2.33c-3.92,1.07-8.03,1.65-13.57,1.65-13.76,0-23.7-5.25-23.7-23.54ZM404.02,52.25h25.52c-.29-13.23-2.87-19.55-12.62-19.55s-12.62,6.52-12.9,19.55Z" style="fill: #040000; stroke-width: 0px;"/>
    <path d="M455.91,30.46h6.98v3.11c3.44-1.95,8.98-3.7,14.72-3.7,10.89,0,17.3,4.57,17.3,17.12v29.57h-7.07v-29.47c0-10.31-3.73-13.81-13.38-13.81-4.49,0-7.93.88-11.37,2.04v41.25h-7.17V30.46Z" style="fill: #040000; stroke-width: 0px;"/>
    <path d="M510.97,30.46h7.36v-13.52h7.07v13.52h12.9v2.72h-12.9v32.98c0,5.84,2.39,7.88,7.74,7.88,2.01,0,3.25-.19,5.16-.58v2.34c-2.2.68-4.2,1.07-7.84,1.07-6.79,0-12.14-2.53-12.14-12.65v-31.03h-7.36v-2.72Z" style="fill: #040000; stroke-width: 0px;"/>
  </g>
  <g>
    <polygon points="0 163.44 66.75 163.44 118.43 83.79 118.43 0 0 163.44" style="fill: #ddb332; stroke-width: 0px;"/>
    <polygon points="175 10.3 175 153.67 182.46 163.44 182.46 0 175 10.3" style="fill: #ddb332; opacity: .4; stroke-width: 0px;"/>
    <polygon points="169.03 145.85 169.03 18.55 161.22 29.32 161.22 135.63 169.03 145.85" style="fill: #ddb332; opacity: .55; stroke-width: 0px;"/>
    <polygon points="155.24 127.81 155.24 37.57 148.56 46.79 148.56 119.06 155.24 127.81" style="fill: #ddb332; opacity: .7; stroke-width: 0px;"/>
    <polygon points="142.58 111.24 142.58 55.04 135.62 64.64 135.62 102.13 142.58 111.24" style="fill: #ddb332; opacity: .85; stroke-width: 0px;"/>
    <polygon points="129.65 72.89 121.69 83.88 129.65 94.3 129.65 72.89" style="fill: #ddb332; stroke-width: 0px;"/>
    <polygon points="175 163.44 182.46 163.44 175 153.67 175 163.44" style="fill: #aeacae; stroke-width: 0px;"/>
    <polygon points="161.22 135.63 161.22 163.44 169.03 163.44 169.03 145.85 161.22 135.63" style="fill: #aeacae; stroke-width: 0px;"/>
    <polygon points="148.56 119.06 148.56 163.44 155.24 163.44 155.24 127.81 148.56 119.06" style="fill: #aeacae; stroke-width: 0px;"/>
    <polygon points="135.62 102.13 135.62 163.44 142.58 163.44 142.58 111.24 135.62 102.13" style="fill: #aeacae; stroke-width: 0px;"/>
    <polygon points="121.69 83.88 121.69 163.44 129.65 163.44 129.65 94.3 121.69 83.88" style="fill: #aeacae; stroke-width: 0px;"/>
  </g>
  <g id="_雋揚建築" data-name="雋揚建築">
    <g>
      <polygon points="335.54 136.47 341.21 124.79 346.36 124.79 340.69 136.47 335.54 136.47" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="351.67 143.98 358.21 130.17 363.41 130.17 356.82 143.98 351.67 143.98" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="341.85 143.98 348.38 130.17 353.58 130.17 346.99 143.98 341.85 143.98" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="322.88 125.43 336.87 119.76 336.87 124.62 322.88 130.22 322.88 125.43" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M361.85,143.98v-4.22h4.57v-7.4h-25.32v-3.53h27.41c2.43,0,2.78.35,2.78,2.78v9.48c0,2.43-.35,2.78-2.78,2.78h-6.59l-.06.12Z" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M323.52,143.98v-4.22h5.2v-34.11h4.8v33.82c0,2.49-2.02,4.45-4.45,4.45h-5.55v.06Z" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="321.38 115.19 323.11 111.67 338.84 111.67 338.84 115.19 321.38 115.19" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M342.08,120.28v-12.66c0-1.04.81-1.91,1.91-1.91h24.4c1.04,0,1.91.81,1.91,1.91v12.66h-28.21ZM344.1,118.95h23.13v-11.45h-23.13v11.45Z" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="343.06 120.28 344.33 116.98 367.23 116.98 367.23 120.28 343.06 120.28" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="343.06 114.56 344.33 111.38 367.23 111.38 367.23 114.56 343.06 114.56" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="343.18 108.89 344.39 105.71 367.29 105.71 367.29 108.89 343.18 108.89" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="342.08" y="107.5" width="3.99" height="11.45" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="366.24" y="107.5" width="2.66" height="11.45" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="336.76 126.35 338.49 123.11 370.29 123.11 370.29 126.35 336.76 126.35" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="342.08" y="107.39" width="4.8" height="11.04" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="365.38 118.43 365.38 106.75 370.29 108.08 370.29 118.43 365.38 118.43" style="fill: #040000; stroke-width: 0px;"/>
    </g>
    <g>
      <polygon points="250 110.1 251.68 106.81 284.29 106.81 284.29 110.1 250 110.1" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="240.17 113.98 244.86 105.02 247.75 105.02 247.75 113.98 240.17 113.98" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="250 115.83 251.68 112.59 284.29 112.59 284.29 115.83 250 115.83" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="250 121.61 251.68 118.43 284.29 118.43 284.29 121.61 250 121.61" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="250 127.39 251.68 124.21 284.29 124.21 284.29 127.39 250 127.39" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="243.18 127.39 243.18 112.42 246.36 105.07 248.5 105.07 248.5 127.39 243.18 127.39" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="264.23" y="104.38" width="5.61" height="20.18" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M258.27,139.53c-2.49,0-2.78-.35-2.78-2.78v-6.36h5.49v5.38h5.84v-5.38h5.49v6.36c0,2.49-.35,2.78-2.78,2.78h-11.27Z" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M243.35,144.04v-9.19c0-2.49,2.02-4.45,4.45-4.45h12.95v4.16h-12.6v9.54h-4.8v-.06Z" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M274.92,144.04v-4.22h4.57v-5.38h-12.66v-4.16h13.07c2.49,0,4.45,2.02,4.45,4.45v4.74c0,2.49-2.02,4.45-4.45,4.45h-4.97v.12Z" style="fill: #040000; stroke-width: 0px;"/>
    </g>
    <g>
      <polygon points="528.62 128.61 528.62 117.68 533.65 118.89 533.65 128.61 528.62 128.61" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="531.63 128.61 532.27 126.58 537.35 126.58 537.35 128.61 531.63 128.61" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M530.47,123.46v-5.15h-6.82v-1.56h8.09c1.1,0,1.91.75,1.91,1.68v4.97h-3.12l-.06.06Z" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="528.97" y="119.07" width="4.68" height="8.56" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="513.53 118.83 514.75 116.75 529.09 116.75 529.09 118.83 513.53 118.83" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="492.54 120.11 494.45 116.87 511.05 116.87 511.05 120.11 492.54 120.11" style="fill: #040000; stroke-width: 0px;"/>
      <path d="M513.53,124.79c5.2-2.31,5.43-5.61,5.43-5.61v-1.1h3.93v1.73c0,.46-.12,1.04-.35,1.5-.23.69-.46,1.33-.81,1.97-.4.81-.93,1.5-1.56,2.2-.75.75-1.56,1.45-2.6,2.02-.81.46-1.56.81-2.25,1.1h-.06s-.52.23-.81.29l-.4.12s-.46.12-.64.12v-4.28h.06l.06-.06Z" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="490.46 134.27 492.37 131.03 538.45 131.03 538.45 134.27 490.46 134.27" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="511.39" y="129.59" width="5.9" height="14.63" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="492.66 144.27 497.81 136.47 503.53 136.47 498.38 144.27 492.66 144.27" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="531.46 144.27 526.31 136.47 532.15 136.47 537.24 144.27 531.46 144.27" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="513.36 121.09 517.93 121.09 524.4 128.61 519.89 128.61 513.36 121.09" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="493.24 125.43 511.05 123.23 511.05 126.81 493.24 129.01 493.24 125.43" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="500 125.95 500 117.97 504.98 118.89 504.98 125.95 500 125.95" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="500.29" y="118.95" width="4.68" height="6.19" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="492.02" y="104.67" width="5.61" height="9.83" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="492.37" y="104.67" width="4.68" height="8.9" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="502.49" y="108.49" width="4.97" height="6.01" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="502.72" y="108.49" width="4.68" height="5.43" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="516.65" y="104.67" width="4.97" height="9.83" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="516.89" y="104.67" width="4.68" height="8.9" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="527.06" y="108.49" width="4.97" height="6.01" style="fill: #040000; stroke-width: 0px;"/>
      <rect x="527.35" y="108.49" width="4.68" height="5.43" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="495.03 110.28 496.88 107.1 514.05 107.1 514.05 110.28 495.03 110.28" style="fill: #040000; stroke-width: 0px;"/>
      <polygon points="518.33 110.28 520.24 107.1 537.3 107.1 537.3 110.28 518.33 110.28" style="fill: #040000; stroke-width: 0px;"/>
    </g>
    <g>
      <g>
        <rect x="446.26" y="108.6" width="2.66" height="11.45" style="fill: #040000; stroke-width: 0px;"/>
        <rect x="445.4" y="109.12" width="4.8" height="13.07" style="fill: #040000; stroke-width: 0px;"/>
        <path d="M427.76,121.32v-1.33h19.43v-11.45h-19.43v-1.73h20.58c1.04,0,1.91.81,1.91,1.91v12.66h-22.49v-.06Z" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="409.38 109.29 410.59 107.04 419.96 107.04 421 109.29 409.38 109.29" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="411.92 121.32 413.31 119.07 420.19 119.07 420.19 121.32 411.92 121.32" style="fill: #040000; stroke-width: 0px;"/>
        <path d="M419.49,108.72h-6.65v-1.73h7.86c.93,0,1.73.69,1.91,1.62v.35l-3.06,4.68v-4.86h-.06v-.06Z" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="413.36 119.76 413.19 119.24 419.61 109.35 422.27 109.35 415.56 119.76 413.36 119.76" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="408.57 121.32 410.07 119.07 416.83 119.07 416.83 121.32 408.57 121.32" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="410.13 119.76 409.95 119.24 416.78 108.49 420.65 108.49 413.48 119.76 410.13 119.76" style="fill: #040000; stroke-width: 0px;"/>
        <path d="M420.59,120.8h-6.65v-1.73h7.86c.93,0,1.73.69,1.91,1.62v.29l-3.06,4.28v-4.45h-.06Z" style="fill: #040000; stroke-width: 0px;"/>
        <rect x="435.1" y="104.67" width="4.8" height="32.67" style="fill: #040000; stroke-width: 0px;"/>
        <path d="M407.7,139.53l.75-.29c.35-.12,9.08-3.93,10.18-19.02l4.34.35s.64.75.69.81c-1.62,18.39-13.12,22.95-13.7,23.18h-.29v.12c-.35.12-1.1.46-2.02.81v-5.9h.06v-.06Z" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="424.52 110.05 426.2 106.75 448.11 106.75 448.11 110.05 424.52 110.05" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="425.45 122.25 427.13 118.95 449.04 118.95 449.04 122.25 425.45 122.25" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="425.45 128.26 427.13 125.02 450.83 125.02 450.83 128.26 425.45 128.26" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="423.08 134.27 424.75 131.09 453.09 131.09 453.09 134.27 423.08 134.27" style="fill: #040000; stroke-width: 0px;"/>
        <polygon points="423.08 116.12 424.75 112.88 453.09 112.88 453.09 116.12 423.08 116.12" style="fill: #040000; stroke-width: 0px;"/>
      </g>
      <path d="M427.3,138.95c-6.13-.75-8.67-4.45-8.79-4.63l-.12-.12c-1.85-2.49-4.05-7.81-4.86-9.94h-5.2s.23.52.23.69c1.16,3.12,3.53,8.73,5.84,11.97.69,1.04,4.63,6.24,13.07,7.17h25.67v-5.09h-25.73l-.12-.06Z" style="fill: #040000; stroke-width: 0px;"/>
    </g>
  </g>
</svg> -->