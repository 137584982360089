
@if(isLoading$ | async) {
<div [classList]="'asnt-loading-main-inner ' + deviceInfo">
    <div class="logo-graphic">
        <img [src]="imagePath + 'loading/asnt-loading-graphic-text.svg'" alt="loading">
    </div>
    <div class="laod-graphic">
        <img [src]="imagePath + 'loading/loading.png'" alt="loading">
    </div>
</div>
}
