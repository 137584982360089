@if(displayMainLoading) {
<asnt-loading-main></asnt-loading-main>
}



@if(displayBody) {

<div [className]="'app-outer-constr ' + deviceInfo">

    <asnt-nav></asnt-nav>
    <router-outlet />
    <asnt-footer></asnt-footer>
    
</div>

}