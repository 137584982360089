import { Component, HostBinding } from '@angular/core';
import { ChangeDetectionStrategy } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Observable, Subscription, filter, startWith } from 'rxjs';
import { PageInfoService } from '../../service/page-info.service';
import { NavList } from './nav-list';
import { LoadTimerService } from '../../service/load-timer.service';
import { CustomDeviceDetectService } from '../../service/custom-device-detect.service';
import { JwtService } from '../../service/util-services/jwt.service';
import { NavigationEnd, Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'asnt-nav',
  templateUrl: './asnt-nav.component.html',
  styleUrl: './asnt-nav.component.less',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AsntNavComponent {

  navList = NavList;
  navListDisplay!: boolean;
  navItemDisplay: boolean = true;
  navContaninerOverflow: boolean = true;
  displayNavItem$!: Observable<boolean>; //顯示按鈕選項
  pageOrder$!: Observable<number>;
  getSubscription: Subscription = new Subscription();


  deviceInfo: string = 'Unknown';

  isLogin: boolean = false;

  @HostBinding('class') get hostClasses(): string {
    return `asnt-nav `;
  }

  constructor(
    private loadTimerService: LoadTimerService,
    private pageInfoService: PageInfoService,
    private viewportScroller: ViewportScroller,
    private customDeviceService: CustomDeviceDetectService,
    private jwtService: JwtService,
    private router: Router,
    private snack: MatSnackBar,
  ) {
    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });

    this.jwtService.isLogin$.subscribe((isLogin) => {
      this.isLogin = isLogin;
    });
  }

  ngOnInit() {
    this.pageOrder$ = this.pageInfoService.getPageOrder();
    this.displayNavItem$ = this.loadTimerService.getNavItemDisplayStatus();
    switch (this.deviceInfo) {
      case 'mobile': {
        this.navListDisplay = false;
        this.navContaninerOverflow = false;
        break;
      }

      case 'tablet': {
        this.navListDisplay = false;
        this.navContaninerOverflow = false;
        break;
      }

      case 'desktop': {
        this.navListDisplay = true;
        break;
      }
    }
  }

  switchNavList() {
    this.navListDisplay = !this.navListDisplay;
  }

  clickAsntNavItem() {
    this.navListDisplay = false;
    this.viewportScroller.scrollToPosition([0, 0]);
  }

  logoutClick() {
    this.snack.open('登出成功', '', { duration: 3000 });

    this.jwtService.removeToken();
    const currentRoute = this.router.url;
    
    if (currentRoute.includes('/member')) {
      this.router.navigate(['/login']);
    }
  }

}
