import { AsntLoadingMainComponent } from './common/asnt-loading/asnt-loading-main/asnt-loading-main.component';
import { LoadTimerService } from './service/load-timer.service';
import { AppPathSetting } from './app-path';
import { Meta } from '@angular/platform-browser';
import { environment } from '../environments/environment';
import { Component, inject } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CustomDeviceDetectService } from './service/custom-device-detect.service';
import { AsntNavModule } from './common/asnt-nav/asnt-nav.module';
import { AsntFooterModule } from './common/asnt-footer/asnt-footer.module';
import { JwtService } from './service/util-services/jwt.service';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    AsntLoadingMainComponent,
    RouterOutlet,
    AsntFooterModule,
    AsntNavModule,
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.less'
})
export class AppComponent {

  title = '雋揚國際 Ascent Global';
  deviceInfo: string = 'Unknown';
  displayBody: boolean = false;
  displayMainLoading: boolean = environment.displayMainLoading;

  //SEO
  meta_descp: string = AppPathSetting.home.meta_descp;
  meta_keywords: string = AppPathSetting.home.meta_keyword;

  schema = {
    '@context': 'http://schema.org',
    '@type': 'WebSite',
    name: '雋揚國際股份有限公司 ASCENT DEVELOPMENT',
    url: 'https://www.ascentglobal.com.tw',
    description: this.meta_descp,
  };

  private jwtService: JwtService = inject(JwtService);
  constructor(
    private loadTimerService: LoadTimerService,
    private customDeviceService: CustomDeviceDetectService,
    private metaTagService: Meta,
  ) {

  }

  ngOnInit() {

    // 首先置入Meta Tag
    this.addMetaTag();

    // 開啟主要Loading等待畫面2.6秒
    setTimeout(() => {
      this.loadTimerService.setMainLoadStatus(false);
    }, 2600);

    // 渲染body
    // 如果開啟主畫面loading 就等待2.4秒
    if (this.displayMainLoading) {
      this.showContent(2400);
      this.showNavItem(2700);
    } else {
      //否則直接渲染
      this.showNavItem(0);
      this.showContent(0);
    }



    // 同步設備類型的值
    this.customDeviceService.deviceInfo$.subscribe((type) => {
      this.deviceInfo = type;
    });

    this.jwtService.isTokenExpired();
  }

  showNavItem(timer: number) {
    setTimeout(() => {
      this.loadTimerService.setNavItemDisplayStatus(true);
    }, timer);
  }

  showContent(timer: number) {
    setTimeout(() => {
      this.displayBody = true;
      this.loadTimerService.setElementDisplayStatus(true);
    }, timer);

  }

  addMetaTag() {
    this.metaTagService.addTags([
      {
        name: 'keywords',
        content: this.meta_keywords,
      },
      {
        name: 'description',
        content: this.meta_descp,
      },
    ]);
  }



}
